import React from "react"
import Layout from "../components/layout"
import { Link as GatsbyLink, graphql } from "gatsby"
import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react"
import get from "lodash/get"

const styles = {
  button: {
    color: "white",
    fontSize: "16px",
    backgroundColor: "#129459",
    borderRadius: "4px",
    width: "100%",
    height: "56px",
    my: "40px",
    _hover: { color: "white" },
  },

  flex: {
    flexDirection: "column",
    width: { base: "100%", md: "100%", lg: "658px" },
    margin: "0 auto",
  },

  heading: {
    color: "#1B263F",
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "56px",
  },
  "heading-box": {
    width: "100%",
  },
  "heading-text": {
    color: "#3399FF",

    lineHeight: "47.88px",
    fontSize: "18px",
    margin: "44px 0px 24px 0px",
  },

  link: {
    margin: "0px 0px 103px 0px",
    display: "block",
    textAlign: "left",
    width: "100%",
  },
  "link-text": {
    color: "#3399FF",

    fontSize: "18px",
  },
  description: {
    width: "100%",
    fontSize: "16px",
    marginTop: "56px",
    "> p": { marginTop: "24px" },
  },
  responsibilities: {
    width: "100%",
    "> ul": { paddingLeft: "20px" },
  },
  qualifications: {
    width: "100%",
    "> ul": { paddingLeft: "20px" },
  },

  "h4-text": {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    my: "24px",
    width: "100%",
  },
}

const CareersPostTemplate = props => {
  const jobPost = get(props, "data.contentfulJobPost")
  const url = get(props, "data.contentfulJobPost.linkedInUrl.linkedInUrl")

  return (
    <Layout location={props.location}>
      <Box sx={{ width: "100%", px: "24px" }}>
        <Flex sx={styles.flex}>
          <Box sx={styles["heading-box"]}>
            <Link as={GatsbyLink} sx={styles["heading-text"]} to="/careers">
              CAREERS
            </Link>
            <Heading sx={styles.heading}>{jobPost?.title}</Heading>
          </Box>
          {/* description */}
          <Box
            sx={styles.description}
            dangerouslySetInnerHTML={{
              __html: jobPost?.description?.childMarkdownRemark?.html,
            }}
          />

          {/*responsibilities*/}
          <Flex sx={styles["h4-text"]}>
            <Text>Key Responsibilities</Text>
          </Flex>
          <Box
            sx={styles.responsibilities}
            dangerouslySetInnerHTML={{
              __html: jobPost?.responsibilities?.childMarkdownRemark?.html,
            }}
          />

          {/* qualifications */}
          <Flex sx={styles["h4-text"]}>
            <Text>Qualifications</Text>
          </Flex>
          <Box
            sx={styles.qualifications}
            dangerouslySetInnerHTML={{
              __html: jobPost?.qualifications?.childMarkdownRemark?.html,
            }}
          />
          <Link href={url} isExternal sx={styles.button}>
            <Text sx={{ textAlign: "center", marginTop: "15px" }}>
              Apply For This Position
            </Text>
          </Link>

          {/* link to careers page*/}
          <Box sx={styles.link}>
            <Link as={GatsbyLink} to={"/careers"} sx={styles["link-text"]}>
              back to careers page
            </Link>
          </Box>
        </Flex>
      </Box>
    </Layout>
  )
}

export default CareersPostTemplate

// https://github.com/gatsbyjs/gatsby/pull/33528
export const pageQuery = graphql`
  query JobPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulJobPost(slug: { eq: $slug }) {
      slug
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      qualifications {
        childMarkdownRemark {
          html
        }
      }
      responsibilities {
        childMarkdownRemark {
          html
        }
      }
      linkedInUrl {
        linkedInUrl
      }
    }
    previous: contentfulJobPost(slug: { eq: $previousPostSlug }) {
      slug
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      qualifications {
        childMarkdownRemark {
          html
        }
      }
      responsibilities {
        childMarkdownRemark {
          html
        }
      }
      linkedInUrl {
        linkedInUrl
      }
    }
    next: contentfulJobPost(slug: { eq: $nextPostSlug }) {
      slug
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      qualifications {
        childMarkdownRemark {
          html
        }
      }
      responsibilities {
        childMarkdownRemark {
          html
        }
      }
      linkedInUrl {
        linkedInUrl
      }
    }
  }
`
